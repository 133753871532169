import { itemUnitUseCase } from '@/domain/usecase'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import DropdownAbsolute from '@/views/shared-components/DropdownAbsolute.vue'
import ItemUnitForm from '@/views/master/items/item-unit/ItemUnitForm.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'ItemUnit',
  components: {
    DropdownAbsolute,
    ItemUnitForm
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const data = ref([])
    const sliderActive = ref(false)
    const dataEdit = ref(null) as any
    const op = ref()
    const inputSearch = ref('')
    const route = useRoute()

    const redirectAdd = () => {
      dataEdit.value = null
      sliderActive.value = true
    }

    const redirectEdit = (item: any) => {
      dataEdit.value = item
      sliderActive.value = true
    }

    const showDropDown = (evt: any, item: any) => {
      dataEdit.value = item
      op.value.toggle(evt)
    }

    const getAllData = async (search = null) => {
      store.dispatch('showLoading')
      const response = await itemUnitUseCase.getAll({
        search
      })
      if (!response.error) {
        data.value = response.result
      } else {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataForm = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await itemUnitUseCase.changeIsActive(dataForm)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successChangeStatus, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const deleteData = (item: any) => {
      $confirm.require({
        header: 'Satuan Barang',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await itemUnitUseCase.deleteData(item.Id)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus satuan barang, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            getAllData()
          }
        }
      })
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    onMounted(() => {
      getAllData()
    })

    return {
      dataEdit,
      data,
      sliderActive,
      op,
      inputSearch,
      route,
      changeIsActive,
      getAllData,
      redirectAdd,
      redirectEdit,
      closeSidebar,
      showDropDown,
      deleteData
    }
  }
}
